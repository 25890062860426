import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { TweenMax, Power3 } from 'gsap'

import Title from '../css/Title'
import InnerDiv from '../css/InnerDiv'

export default function FunkyTitle({ title }) {
  let funkyTitleText = useRef(null)
  useEffect(() => {
    TweenMax.from(funkyTitleText, { y: 200, ease: Power3.easeIn, duration: 0.3, delay: 0 })
    TweenMax.to(funkyTitleText, { opacity: 1, ease: Power3.easeOut, duration: 0, delay: 0.3 })
  }, [])
  return (
    <InnerDiv>
      <Title ref={el => (funkyTitleText = el)}>{title}</Title>
    </InnerDiv>
  )
}

FunkyTitle.propTypes = {
  title: PropTypes.string
}
