import styled from 'styled-components'

import { ShadowAltStyle } from './Styles'

const ExternalLink = styled.a`
  position: relative;
  font-weight: 700;
  text-decoration: none;
  text-shadow: ${ShadowAltStyle};
`

export default ExternalLink
