import styled from 'styled-components'

const Section = styled.section`
  padding: 8rem 0rem 8rem 0rem;
  color: var(--color);
  transform: skewY(-2deg);
  background-color: var(--bgSection);
  @media (max-width: 768px) {
    padding: 4rem 0rem 4rem 0rem;
  }
`

export default Section
