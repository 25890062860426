import styled from 'styled-components'
import React from 'react'
import { Link } from 'gatsby'

import { ShadowAltStyle } from './Styles'

const PageLink = styled((props) => <Link {...props} />)`
  position: relative;
  font-weight: 700;
  text-decoration: none;
  text-shadow: ${ShadowAltStyle};
`

export default PageLink
