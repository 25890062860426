import styled from 'styled-components'

import { ShadowStyle } from './Styles'

const Title = styled.h1`
  font-size: 4rem;
  letter-spacing: 0.125rem;
  text-align: center;
  font-weight: 800;
  text-shadow: ${ShadowStyle};
  opacity: 0;
  @media (max-width: 768px) {
    font-size: 3rem;
  }
`

export default Title
