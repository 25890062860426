import React from 'react'

import Section from '../css/Section'
import Content from '../css/Content'
import PageLink from '../css/PageLink'
import ExternalLink from '../css/ExternalLink'
import Layout from '../components/Layout'
import FunkyTitle from '../components/FunkyTitle'

const pageTitle = 'About Me'

function AboutPage(props) {
  return (
    <>
      <FunkyTitle title={pageTitle} />
      <Section>
        <Content>
          Hello! I’m Mustafa, a software engineer based in Dubai &amp; currently
          working at Crescent Enterprises. I&apos;m part of the CE-Creates team,
          building exciting new projects. I love learning new things related to
          programming and beyond. I spend a lot of my free time reading &amp;
          travelling or diving into interesting titbits of info. I enjoy coding
          as a hobby &amp; I really enjoy listening to a ton of&nbsp;
          <PageLink to="/podcasts/">podcasts</PageLink>.&nbsp; I also wish to
          develop this space &amp; I will be building upon this in the near
          future! If you would like to have a chat, you can always reach me on{' '}
          <ExternalLink href="https://twitter.com/mustafazc">
            twitter
          </ExternalLink>{' '}
          !
        </Content>
      </Section>
    </>
  )
}

AboutPage.Layout = Layout
AboutPage.pageTitle = pageTitle

export default AboutPage

// Hello! I’m Mustafa, a software engineer based in Dubai &amp; currently
// working at Crescent Enterprises. I&apos;m part of the CE-Creates team,
// building exciting new projects. I love learning new things related to
// programming and beyond. I spend a lot of my free time{' '}
// <PageLink to="/bookshelf">reading</PageLink> or browsing reddit for
// interesting titbits. I enjoy coding as a hobby &amp; I really enjoy
// listening to a ton of&nbsp;
// <PageLink to="/podcasts">podcasts</PageLink>.&nbsp; I also wish to
// develop this space &amp; I will be building upon this in the near
// future! If you would like to have a chat, you can always reach me on{' '}
// <ExternalLink href="https://twitter.com/mustafazc">
//   twitter
// </ExternalLink>{' '}
// !
